.App {
  font-family: sans-serif;
  text-align: center;
}


.red {
  background-color: rgba(255, 127, 174, 0.629);
}

.orange {
  border: 3px solid rgba(236, 7, 7, 0.629);
}
.yellow {
  border: 1px solid rgba(221, 236, 7, 0.629);
}

.blue {
  background-color: rgba(127, 165, 255, 0.718);
}



.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}